import { Injectable } from '@angular/core';
import { getCloudStorageBackend} from '../../firebaseBackend/cloudStorage';

@Injectable({
  providedIn: 'root'
})
export class CloudStorageService {

  constructor() { }
  uploadFile(bucket:string,file: File, path: string, cb){
    return getCloudStorageBackend().filePromising(bucket,file,path,(out:any,err:boolean)=>{
        cb(out,err)
    })
  }

  getListFromSpecificFile(bucket:string,type:string,path:string,status){
    return getCloudStorageBackend().listAll_(bucket,type,path,status).then((res:any)=>{
      return res;
    })
  }

  deleteFile(bucket:string,path:string){
    return getCloudStorageBackend().deleteFile(bucket,path).then((res:any)=>{
      return res;
    })
  }

  getFileUrl(bucket:string,path:string){
    return getCloudStorageBackend().getFileUrl(bucket,path).then((res:any)=>{
      return res;
    })
  }



}
