import { Injectable } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { rest_analytics_version } from '../../../environments/environment';
import { SharedOperationsService } from './shared-operations.service';
import { Observable ,Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  constructor(private dashboardService:DashboardService,private sharedOperationsService:SharedOperationsService) { }
  authUser = this.sharedOperationsService.getAuthenticatedUser();

  result$: Observable<any>;
  

  sendLogRequest(projectId:string,log:any){
    const url = `${rest_analytics_version.replace('api/v1/', '')}collect_console_logs`;
    const authUser = this.sharedOperationsService.getAuthenticatedUser();
    // console.log({url});

    let requestBody = {
      kainic_pid: projectId,
      logs: [{
        log_id: this.sharedOperationsService.generateApiKey(),
        uid: authUser.email,
        log_sent_ts: new Date().getTime(),
        log_timezone: 'UTC', 
        ...log
      }]
    }

    console.log({requestBody});
    this.result$ =this.dashboardService.postRequest(url,requestBody);
    this.result$.subscribe(res => {
      // console.log({res});
    })
  }
}
