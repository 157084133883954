import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CredentialsService {

  private dataSource = new BehaviorSubject(null);
  currentDocData = this.dataSource.asObservable();

  constructor() { }

  setDocumentData(docData:any) {
    this.dataSource.next(docData);
    //console.log("from service of creditioal")
  }

}
