<div>
    <ng-template #credentialsModal let-modal>
        <div class="modal-header">
          <h5 class="modal-title mt-0">Credentials</h5>
          <button type="button" class="close" (click)="modal.dismiss('Cross click');closeVisiabilty();"
            aria-hidden="true">×</button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="float-right">
                <a class="col-4" [class.disabled]='!visibility' [class.enabled]='visibility' href="javascript:void(0);"
                  (click)='onChangeAPIkeyClick(confirmationModel)'>Change Key</a>
                <a class="col-4" style="color: whitesmoke;" href="javascript:void(0);" (click)='changeVisiablity()'>Show
                  Credentials<i class="bx bx-xs" [class.bx-show]="visibility" [class.bx-hide]="!visibility"></i></a>
              </div>
            </div>
            <form class="col-12" role="form">
              <div class="form-group">
                <label class="control-label">API key:</label>
                <div class="d-flex flex-row align-items-center justify-content-end">
                  <input class="form-control" [type]="!visibility ? 'password' : 'text'" readonly value={{credentials}}>
                  <i style="
                  position: absolute;
                  padding: 5px;
                  z-index: 10;" (click)="copyAPIkeyToClipboard()" class="bx bx-sm bx-copy"></i>
                </div>
              </div>
            </form>
          </div>
        </div>
      </ng-template>
      <ng-template #confirmationModel let-modal>
        <div class="modal-header">
          <h5 class="modal-title mt-0">Change API Key</h5>
          <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
        </div>
        <div class="modal-body">
          <div class="row">
            <form class="col-12" role="form">
              <div class="d-flex flex-column align-items-center justify-content-center">
                <h1>Change API Key</h1>
                <p>Are you sure you want to Change your API key?</p>
              </div>
              <div class="col-12 d-flex flex-row justify-content-between">
                <button type="button" class="col-6 btn btn-secondary" (click)='modal.close();'>Cancel</button>
                <button type="button" class="col-6 btn btn-primary"
                  (click)='onChangeAPIKeyConfirmed();modal.close();'>Change</button>
              </div>
            </form>
          </div>
        </div>
      </ng-template>
</div>
