import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private projectName = new BehaviorSubject('Please Select a Project');
  currentProjectName = this.projectName.asObservable();

  private userName = new BehaviorSubject('');
  currentUsername = this.userName.asObservable();

  private userPicture =new BehaviorSubject('assets/images/users/avatar-0.jpg');
  currentUserPicture=this.userPicture.asObservable();

  private createTrackerStatus =new BehaviorSubject(false);
  createTrackerSubmitted=this.createTrackerStatus.asObservable();

  private uploadStatus= new BehaviorSubject(false);
  uploadStatusValue=this.uploadStatus.asObservable();

  private createModelStatus= new BehaviorSubject(false);
  createModelSubmitted=this.createModelStatus.asObservable();

  private createTrainingJobStatus= new BehaviorSubject(false);
  createTrainingJobSubmitted=this.createTrainingJobStatus.asObservable();

  private projectData =new BehaviorSubject(null);
  currentProjectData=this.projectData.asObservable();

  private submittDataStatus=new BehaviorSubject(new String); 
  dataFormSubmitted=this.submittDataStatus.asObservable();

  private accountTab=new BehaviorSubject(new String);
  currentAccountTab=this.accountTab.asObservable();

  constructor() { }

  changeProjectName(Pname: string) {
    this.projectName.next(Pname)
  }

  changeUserName(userName: string) {
    this.userName.next(userName)
  }
  changeUserPic(pictureURL:string){
    this.userPicture.next(pictureURL);
  }

  changeProjectData(project: any){
    this.projectData.next(project)
  }

  changeCreateTrackerSubmittionStatus(){
    this.createTrackerStatus.next(true);
  }

  UploadStatus(){
    this.uploadStatus.next(true);
  }

  changeCreateModelSubmittionStatus(){
    this.createModelStatus.next(true);
  }

  changeCreateTrainingJobSubmittionStatus(){
    this.createTrainingJobStatus.next(true)
  }

  onSubmittForm(formName:string){
    this.submittDataStatus.next(formName)
  }

  changeCurrentAccountTab(tabName:string){
    this.accountTab.next(tabName);
  }

}
