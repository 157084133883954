import { MenuItem } from './menu.model';

export const getMenu = (projectId) => {
    const menu: MenuItem[] = [
        {
            label: 'SERVICES',
            isTitle: true,
        },
        {
            label:'Wizard',
            icon:'bx-sort-down',
            link:`/projects/${projectId}/dashboards/onboarding`
        },
        {
            label:'Home',
            icon:'bx-home',
            link:`/projects/${projectId}`
        },
        {
            label: 'Data',
            icon: 'bx-receipt',
            subItems: [
                {
                    label: 'Ingest',
                    link: `/projects/${projectId}/trackers/`,
                },
                {
                    label: 'Products',
                    link: `/projects/${projectId}/products-connectors`
                }
            ]
        },
        {
            label: 'AI',
            icon: 'bx-blanket',
            subItems: [
                {
                    label: 'Recommendations',
                    link:`/projects/${projectId}/recommendations`,
                },
                {
                    label: 'Experiments',
                    link:`/projects/${projectId}/ab-testing`
                }
            ]
        },
        {
            label: 'Operation',
            icon: 'bx-task',
            subItems: [
                {
                    label: 'Monitoring',
                    link: '',
                },
                {
                    label: 'Logging',
                    link: '',
                },
                {
                    label: 'Error Reporting',
                    link: '',
                },
            ]
        },
        {
            label: 'Support',
            icon: 'bx-support',
        },
        {
            label: 'Billing',
            icon: 'bx-money',
            link: `/projects/${projectId}/billing`
        },
        {
            label: 'Settings',
            icon: 'bx-cog',
            subItems: [
                {
                    label: 'Project Selector',
                    link: '',
                },
                {
                    label: 'Project Member',
                    link: `/projects/${projectId}/members`,
                },
                {
                    label: 'Integrations',
                    link: `/projects/${projectId}/integrations`
                }
            ]
        }
    ];

    return menu;
}

export let MENU: MenuItem[] = [
    {
        label: 'SERVICES',
        isTitle: true,
    },
    {
        label:'Wizard',
        icon:'bx-sort-down',
        link:''
    },
    {
        label:'Home',
        icon:'bx-home',
        link:''
    },
    {
        label: 'Data',
        icon: 'bx-receipt',
        subItems: [
            {
                label: 'Ingest',
                link: '',
            },
            {
                label: 'Products',
                link: ''
            }
        ]
    },
    {
        label: 'AI',
        icon: 'bx-blanket',
        subItems: [
            {
                label: 'Recommendations',
                link: '',
            },
            {
                label: 'Experiments',
                link: ''
            }
        ]
    },
    {
        label: 'Operation',
        icon: 'bx-task',
        subItems: [
            {
                label: 'Monitoring',
                link: '',
            },
            {
                label: 'Logging',
                link: '',
            },
            {
                label: 'Error Reporting',
                link: '',
            },
        ]
    },
    {
        label: 'Support',
        icon: 'bx-support',
    },
    {
        label: 'Billing',
        icon: 'bx-money',
        link: ''
    },
    {
        label: 'Settings',
        icon: 'bx-cog',
        subItems: [
            {
                label: 'Project Selector',
                link: '',
            },
            {
                label: 'Project Member',
                link: '',
            },
            {
                label: 'Integrations',
                link: ''
            }
        ]
    }

];

