import { Injectable ,NgZone} from '@angular/core';

import { getFirebaseBackend } from '../../firebaseBackend/authUtils';

import { User } from '../models/auth.models';

import { Router } from "@angular/router";


@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    user: User;

    constructor(private router:Router,private ngZone: NgZone) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        return getFirebaseBackend().getAuthenticatedUser();
    }

    /**
     * Send verification mail
     */
    sendVerificationMail(){
        return getFirebaseBackend().SendVerificationMail().then(()=>{
        })
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
        return getFirebaseBackend().loginUser(email, password).then((response: any) => {
            const user = response;
            // if (user.emailVerified !== true) {
            //     window.alert('Please validate your email address. Kindly check your inbox.');
            // } 
            return user;
        });
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     * @param firstName firstName
     * @param lastName lastName
     */
    register(userData:any) {
        // console.log("from auth service:",userData);
        return getFirebaseBackend().registerUser(userData).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend().forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }

    /** 
     * @param passowrd current password
     */
    checkCurrentPaswword(password:string){
        return getFirebaseBackend().checkCurrentPassword(password).then((response: Boolean)=>{
            return response
        })
    }
    /**
     * @param password new password
     */
    updatePassword(passowrd:string){
        return getFirebaseBackend().updatePassword(passowrd).then((res:any)=>{
            return res;
        })
    }

    /**
     * return user email verification status
     */
    isUserEmailVerified(){
      return  getFirebaseBackend().isUserEmailVerified();
    }
    /**
     * Logout the user
     */
    logout() {
        // logout the user
        getFirebaseBackend().logout();
    }
}

