import { Injectable } from '@angular/core';
import { async } from 'rxjs/internal/scheduler/async';

import { getFirebaseBackend } from '../../firebaseBackend/firestoreUtils';

@Injectable({
  providedIn: 'root'
})
export class AuthorityService {

  constructor() { }

  getUserROleWithCallBack=async(UID:string,PID:string,cb)=>{
    try {
      const docPathUser = `users/${UID}`;
      let user:any=await getFirebaseBackend().getDocumentByDocPath(docPathUser);
      let role=user.projects[PID].role;
      cb(role,false);
    } catch (e) {
      cb(false,'error in getting user role');
    }
  }
  getUserRole(UID:string,PID: string) {
    const docPathUser = `users/${UID}`;
    var role;
    return getFirebaseBackend().getDocumentByDocPath(docPathUser).then((user: any) => {
       role = user.projects[PID].role;
      return role;
    })
  }
  changeUserRole(UID:string,PID:string,newRole:string){
    const docPathUser = `users/${UID}`;
    
    return getFirebaseBackend().getDocumentByDocPath(docPathUser).then((user: any) => {
      var projects=user.projects;
      projects[PID].role=newRole;
      getFirebaseBackend().editDocumentByDocPath(docPathUser,{projects:projects});
      return projects
    })
  }
  hasEditPrivilege(PID: string) {
    var AuthUser = getFirebaseBackend().getAuthenticatedUser();
    var role: any = this.getUserRole(AuthUser.uid,PID);
    // console.log("from edit",role);
    if (role === "editor" || role === "admin" || role === "owner") {
      // console.log("from true case")
      return true;
    }
    else {
      return false;
    }
  }
  hasAdminPrivilege(PID: string) {
    var AuthUser = getFirebaseBackend().getAuthenticatedUser();
    var role: any = this.getUserRole(AuthUser.uid,PID);
    if (role === "admin" || role === "owner") {
      return true;
    }
    else {
      return false;
    }
  }
  isAnOwner(PID: string) {
    var AuthUser = getFirebaseBackend().getAuthenticatedUser();
    var role: any = this.getUserRole(AuthUser.uid,PID);
    if (role === "owner") {
      return true;
    }
    else {
      return false;
    }
  }
}
