<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                Copyright © {{year}} Kainic.ai . All Rights Reserved.
            </div>
            <div class="col-sm-6">
            </div>
        </div>
    </div>
</footer>