import { PathLocationStrategy } from '@angular/common';
import * as firebase from 'firebase/app';
import 'firebase/storage';

class CloudStorageBackend {
  constructor() { }
  filePromising = (bucket:string,file: File, path: string, cb) => {
    let out = { "progress": 0, "snapshot": null, "URL": null };
    let storage = firebase.app().storage("gs://" + bucket);
    const ref = storage.ref(path);
    // The main task
    const task = ref.put(file);

    task.on(firebase.storage.TaskEvent.STATE_CHANGED,
      function progress(snapshot) {
        var progress: number = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        out.progress = progress;
        out.snapshot = snapshot;
        //console.log("out::",out);
        cb(out, false);
      },
      function error(err) {
        //console.log(err)
        cb(err, true);
      },
      function complete() {
        ref.getDownloadURL().then((URL) => {
          //console.log("completed")
          //console.log("Url:", URL);
          out.URL = URL
          cb(out, false);
        });
        
      })
  }
  listAll(bucket: string, type: string, path: string, status) {
    const storageRef = firebase.app().storage("gs://" + bucket).ref();
    let listRef = storageRef.child(path + type);
    return new Promise((resolve, reject) => {
      var list = [];
      listRef.listAll()
        .then((res) => {
          let count = 0;
          res.items.forEach((item: any) => {
            let forestRef = storageRef.child(path + type + "/" + item.name);
            forestRef.getMetadata()
              .then((metadata) => {
                let data = { name: item.name, created_at: metadata.timeCreated, status: status }
                list.push(data)
              })
            count = count + 1;
          })
          setTimeout(function () {
            //console.log("from ", status, " data= ", list);
            resolve({ data: list, length: count });
          }, 1000)

        }).catch((error) => {
          reject(error)
        });
    })
  }
  listAll_(bucket: string, type: string, path: string, status) {
    return new Promise((resolve, reject) => {
      const storageRef = firebase.app().storage("gs://" + bucket).ref();
      let listRef = storageRef.child(path + type);
      let list = [];
      listRef.listAll().then((res) => {
        res.items.forEach((item: any) => {
          this.getFileCreationDataFromMetaData(item,bucket,type,path,status).then((file:any)=>{
            list.push(file);
          })
        });
        setTimeout(() => {
          //console.log("from ", status, " data= ", list);
          resolve({ data: list, length: list.length });
        }, 2000);
      }).catch((error) => {
        reject(error)
      });
    })
  }
  getFileCreationDataFromMetaData(file: any, bucket: string, type: string, path: string, status) {
    return new Promise((resolve, reject) => {
      const storageRef = firebase.app().storage("gs://" + bucket).ref();
      let forestRef = storageRef.child(path + type + "/" + file.name);
      forestRef.getMetadata().then((metadata) => {
        let data = { name: file.name, created_at: metadata.timeCreated, size:metadata.size, status: status }
        resolve(data)
      }).catch((error) => {
        reject(error)
      });
    })
  }
  getFileUrl(bucket:string,path: string){
    return new Promise((resolve, reject) => {
      const storageRef= firebase.app().storage("gs://" + bucket).ref();
      storageRef.child(path).getDownloadURL()   
      .then((url) => {
        //console.log({url});
        resolve(url);
      })
      .catch((error) => {
        reject(error);
      });
    });
  }
  deleteFile = (bucket:string,path: string) => {
    return new Promise((resolve, reject) => {
      let storage = firebase.app().storage("gs://"+bucket);
      const ref = storage.refFromURL(path);
      ref.delete().then(() => {
        resolve(true);
        //console.log("deleting File Done");
      }).catch((error) => {
        reject(error);
        //console.log("Error in deleting File");
      });
    })


  }
}


let _CloudStorageBackend = new CloudStorageBackend();


const getCloudStorageBackend = () => {
  return _CloudStorageBackend;
};

export { getCloudStorageBackend };