import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ProjectService } from '../../core/services/project.service';
import { AuthenticationService } from '../../core/services/auth.service';
import { UserService } from '../../core/services/user.service';
import { DataService } from '../../core/services/data.service';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { SharedOperationsService } from '../../core/services/shared-operations.service';
import { CloudStorageService } from '../../core/services/cloud-storage.service';
import { gcp_project_id } from 'src/environments/environment';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {

  element;
  configData;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  userName;
  projectSelector: string='Please Select a Project';
  projectsData;

  projectId:string;

  projectNameSubscription: Subscription;
  userNameSubcription: Subscription;
  userPictureSubcription: Subscription;

  auth_User:any;
  userPhoto;

  constructor(
    @Inject(DOCUMENT) private document: any, 
    private route: ActivatedRoute, 
    private router: Router, 
    private ProjectService: ProjectService,
    public languageService: LanguageService,
    public translate: TranslateService,
    public authService: AuthenticationService,
    public userService: UserService,
    public _cookiesService: CookieService,
    private SharedOperationsService:SharedOperationsService,
    private CloudStorageService:CloudStorageService,
    private data: DataService) {}

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.getUserProjects();

    this.getCurrentProjectId();

    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.getCurrentProjectId();
      }
    });

    this.userPictureSubcription= this.data.currentUserPicture.subscribe(message=> this.userPhoto=message);

    this.userNameSubcription = this.data.currentUsername.subscribe(message => this.userName = message);

    this.getUserData().then((fullName) => {
      this.userName = fullName;
    });

    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3
    };

    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }

    this.getUserPicture();
  }

  getCurrentProjectId(){
    const currentProjectId = this.route.snapshot['_urlSegment'].segments[1]?.path;
    if(currentProjectId&& this.projectId != currentProjectId && currentProjectId != 'create'){
      this.projectId = currentProjectId;
      this.ProjectService.getProjectData(this.projectId).then((project:any)=>{
        if(project.name){
          const projectExist = this.projectsData.find((proj)=> proj.id === this.projectId);
          if(!projectExist){
            this.getUserProjects();
          }
        }
        this.projectSelector = project.name ? project.name : 'Please Select a Project';
      })
    }
  }

  goToProjectHome(){
    this.router.navigate([`/projects/${this.projectId}`]);
  }

  getUserPicture(){
    let uid=this.SharedOperationsService.getAuthenticatedUser().uid;
    const path = `/users/${uid}/profile.jpg`;
    let bucket=`${gcp_project_id}.appspot.com`;
    this.CloudStorageService.getFileUrl(bucket,path).then((url:any)=>{
      this.userPhoto=url;
    }).catch((err)=>{
      this.userPhoto=null;
    })
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  
  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  getUserProjects() {
    this.ProjectService.getUserProjects().then((projects) => {
      this.projectsData = projects;
    });
  }
  /**
   *  Get user first name and Last name
   */
  getUserData() {
    return this.userService.getUserData().then((user: any) => {
      var auth_user = this.SharedOperationsService.getAuthenticatedUser();
      // this._cookiesService.set(,)
      var fullName = auth_user.firstName + " " + auth_user.lastName;
      return fullName;
    });
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.SharedOperationsService.clearCookieCash();
    this.SharedOperationsService.clearStorage();
    this.authService.logout();
    this.router.navigate(['/account/login']);
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  onProjectclick(project: any) {
    // this.data.changeProjectName(project.name);
    this.SharedOperationsService.setKainicPidInCoockie(project.id);
    this.router.navigate([`projects/${project.id}`]);
  }
  
}
