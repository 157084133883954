import * as firebase from 'firebase/app';
// Add the Firebase products that you want to use
import * as Firsotre from './firestoreUtils';
import 'firebase/auth';
import { gcp_project_id } from 'src/environments/environment';
export interface User {
    firstName: string,
    lastName: string
}
class FirebaseAuthBackend {
    constructor(firebaseConfig) {
        if (firebaseConfig) {
            // Initialize Firebase
            firebase.initializeApp(firebaseConfig);
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    localStorage.setItem('authUser', JSON.stringify(user));
                } else {
                    localStorage.removeItem('authUser');
                }
            });
            firebase.auth().onIdTokenChanged(function(user) {
                if (user) {
                    localStorage.setItem('authUser', JSON.stringify(user));
                }
            });
        }
    }

    /*
    *  Send verification email
     */
    SendVerificationMail(){
        return new Promise((resolve,reject)=>{
            const type =gcp_project_id.split('-')[0];
            let URL= 'console.kainic.ai';
            if(type==='development'){
                URL="dev-console.kainic.net";
            }
            var actionCodeSettings = {
                url: `https://${URL}/account/login`,
                handleCodeInApp: true,
            };
            firebase.auth().currentUser.sendEmailVerification(actionCodeSettings).then(()=>{
                //console.log("verification email sended");
                resolve(true);
            }).catch((err)=>{
                reject(err);
            })
        })
    }

    /**
     * Registers the user with given details
     */
    registerUser = (userData: any) => {
        //console.log("from authUtils :",userData);
        return new Promise((resolve, reject) => {
            firebase.auth().createUserWithEmailAndPassword(userData.email, userData.password).then((user: any) => {
                var user: any = firebase.auth().currentUser;
                //Send Verification email
                this.SendVerificationMail();
                /////////////////////////////
                delete userData.password;
                this.checkAndGetUserByEmail(userData.email).then((doc: any) => {
                    //console.log("getted user by email", doc);
                    if (doc !== null) {
                        this.updateUserRecordByDeleteOldOne(doc, user.uid, userData);
                        if (doc.projects) {
                            //console.log("their is a projects")
                            this.updateProjectUsers(Object.keys(doc.projects), user.uid, doc.id);
                        }
                        if (doc.companies) {
                            //console.log("their is a companies")
                            this.updateCompanyUsers(Object.keys(doc.companies), user.uid, doc.id);
                        }
                    }
                    else {
                        this.creatNewUser(user.uid, userData)
                    }
                })
                resolve(user);
            }, (error) => {
                reject(this._handleError(error));
            });
        });
    }

    checkAndGetUserByEmail(email) {
        return Firsotre.getFirebaseBackend().getDocBySearchAuniqeProperty("users", "email", email).then((res) => {
            return res
        })
    }
    creatNewUser(uid: string, Data: any) {
        return Firsotre.getFirebaseBackend().createDocumentWithSetIDAndcollectionPath(uid, `/users`, Data).then((res: any) => {
            //console.log("new user created  uid=", uid, "  res=", res)
            sessionStorage.setItem('Username', Data.firstName + ' ' + Data.lastName);
        })
    }
    updateUserRecordByDeleteOldOne(oldDoc: any, newUID: string, userData: any) {
        delete userData.email;
        //console.log("old user data after email delection :", oldDoc);
        const userOldDocPth = `users/${oldDoc.id}`;
        const newData = { ...userData, ...oldDoc };
        delete newData.id;
        //console.log("new user data after old id delection :", newData);
        Firsotre.getFirebaseBackend().deleteDocumentByDocPath(userOldDocPth);
        this.creatNewUser(newUID, newData);
    }

    updateProjectUsers(projectsList, newUid: string, oldUid: string) {
        //console.log("from update project Users");
        projectsList.forEach(pid => {
            const projectDocPath = `projects/${pid}`;
            Firsotre.getFirebaseBackend().getDocumentByDocPath(projectDocPath).then((project: any) => {
                const projectIndex = project.users.findIndex((obj => obj == oldUid));
                //console.log("user oldID index== ", projectIndex)
                project.users[projectIndex] = newUid;
                //console.log("project after update user", project);
                delete project.id
                Firsotre.getFirebaseBackend().editDocumentByDocPath(projectDocPath, project);
            })
        });
    }
    updateCompanyUsers(companyList, newUid: string, oldUid: string) {
        //console.log("from update companies Users");
        companyList.forEach(cid => {
            const companyDocPath = `companies/${cid}`;
            Firsotre.getFirebaseBackend().getDocumentByDocPath(companyDocPath).then((company: any) => {
                const companyIndex = company.users.findIndex((obj => obj == oldUid));
                //console.log("user oldID index== ", companyIndex)
                company.users[companyIndex] = newUid;
                //console.log("company after update user", company);
                delete company.id
                Firsotre.getFirebaseBackend().editDocumentByDocPath(companyDocPath, company);
            })
        });
    }


    /**
     * Login user with given details
     */
    loginUser = (email, password) => {
        return new Promise((resolve, reject) => {
            firebase.auth().signInWithEmailAndPassword(email, password).then((res: any) => {
                // eslint-disable-next-line no-redeclare
                this.setLoggeedInUser(res.user)
                //console.log("user from login:",res.user);
                var user: any = firebase.auth().currentUser;    
                resolve(user);
            }, (error) => {
                reject(this._handleError(error));
            });
        });
    }
    /**
     * forget Password user with given details
     */
    forgetPassword = (email) => {
        return new Promise((resolve, reject) => {
            // tslint:disable-next-line: max-line-length
            firebase.auth().sendPasswordResetEmail(email, { url: window.location.protocol + '//' + window.location.host + '/login' }).then(() => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            });
        });
    }
    /**
     * check current password
     */
    checkCurrentPassword(providedPassword: string) {
        return new Promise((resolve, reject) => {
            let user = firebase.auth().currentUser;
            let credential = firebase.auth.EmailAuthProvider.credential(
                firebase.auth().currentUser.email,
                providedPassword
            );
            //console.log({ credential });
            user.reauthenticateWithCredential(credential).then(function () {
                resolve(true)
            }).catch(function () {
                reject(false)
            });
        })
    }
    /**
     * Update user Password
     */
    updatePassword (password: string) {
        return new Promise((resolve, reject) => {
            const user = firebase.auth().currentUser;
            user.updatePassword(password).then(() => {
                resolve(true)
            }).catch((error) => {
                reject(false)
            });
        })
    }
    /**
     * Logout the user
     */
    logout = () => {
        return new Promise((resolve, reject) => {
            firebase.auth().signOut().then(() => {
                localStorage.clear();
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            });
        });
    }

    setLoggeedInUser = (user) => {
        localStorage.setItem('authUser', JSON.stringify(user));
    }

    /**
     * Returns the authenticated user
     */
    getAuthenticatedUser = () => {
        if (!localStorage.getItem('authUser')) {
            return null;
        }
        return JSON.parse(localStorage.getItem('authUser'));
    }

    /**
     * return user emailVerification status
     */
    isUserEmailVerified = () =>{
        const user=this.getAuthenticatedUser();
        if(user){
            //console.log("verification status=",user.emailVerified);
            return user.emailVerified ;
        }
        else{
            return null;
        }

    }

    /**
     * Handle the error
     * @param {*} error
     */
    _handleError(error) {
        // tslint:disable-next-line: prefer-const
        var errorMessage = error.message;
        return errorMessage;
    }
}

// tslint:disable-next-line: variable-name
let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config) => {
    if (!_fireBaseBackend) {
        _fireBaseBackend = new FirebaseAuthBackend(config);
    }
    return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
    return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend };
