import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../models/auth.models';
import { SharedOperationsService } from './shared-operations.service';

import { getFirebaseBackend } from '../../firebaseBackend/firestoreUtils';


@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient,private SharedOperationsService: SharedOperationsService) { }

    getAll() {
        return this.http.get<User[]>(`/api/login`);
    }

    register(user: User) {
        return this.http.post(`/users/register`, user);
    }


    /**
     * get user data
     */
    getUserData() {
        var AuthUser=getFirebaseBackend().getAuthenticatedUser();
        const docPath=`users/${AuthUser.uid}`;
        return getFirebaseBackend().getDocumentByDocPath(docPath).then((userData: any) => {
            AuthUser.firstName = userData.firstName;
            AuthUser.lastName = userData.lastName;
            this.SharedOperationsService.setAuthenticatedUser(AuthUser);
            return userData;
        });

    }
    /**
     * edit user data
     */
    editUserData(user:any) {
        var AuthUser=getFirebaseBackend().getAuthenticatedUser();
        const docPath=`users/${AuthUser.uid}`;
        return getFirebaseBackend().editDocumentByDocPath(docPath,user).then((userData: any) => {
            return userData;
        });
    }

    getSpacificUserData(uid,cb){
        const docPath=`users/${uid}`;
        getFirebaseBackend().getDocumentWithListener(docPath,(user:any,err)=>{
            cb( user,err);
        })
    }

    getUserDataWithID(uid){
        const docPath=`users/${uid}`;
        return getFirebaseBackend().getDocumentByDocPath(docPath).then((userData:any)=>{
            return userData;
        })
    }


    checkAndGetUserByEmail(email){
        return getFirebaseBackend().getDocBySearchAuniqeProperty("users","email",email).then((res)=>{
            return res
         })
    }
    creatNewUser(uid:string,Data:any){
        return getFirebaseBackend().createDocumentWithSetIDAndcollectionPath(uid,`/users`,Data).then((res:any)=>{
            //console.log("new user created res=",res)
            sessionStorage.setItem('Username', Data.firstName + ' ' + Data.lastName);
        })
    }
    updateUserRecordByDeleteOldOne(userDoc:any,newUID:string,userData:any){
        delete userData.email;
        //console.log("old user data after email delection :",userDoc);
        const userOldDocPth=`users/${userDoc.id}`;
        const newData={...userData,...userDoc}
        delete newData.id;
        //console.log("new user data after old id delection :",newData);
        getFirebaseBackend().deleteDocumentByDocPath(userOldDocPth);
        this.creatNewUser(newUID,newData);
    }

    createTempUser(Data:any){
        const collectionPath='users'
        return getFirebaseBackend().createNewDocumentBycollectionPath(collectionPath,Data).then((res:any)=>{
            //console.log("new TEMP user created res=",res)
            sessionStorage.setItem('Username', Data.firstName + ' ' + Data.lastName);
            return res
        })
    }
}
