<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="javascript:void(0);" (click)="goToProjectHome()" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo.svg" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="17">
          </span>
        </a>

        <a href="javascript:void(0);" (click)="goToProjectHome()" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-light-svg.png" alt="" height="33">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.png" alt="" height="30">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- App Search-->
      <!--<form class="app-search d-none d-lg-block">
        <div class="position-relative">
          <input type="text" class="form-control" placeholder="{{'HEADER.SEARCH' | translate}}">
          <span class="bx bx-search-alt"></span>
        </div>
      </form>-->

      <div class="dropdown-mega d-none d-lg-block ml-2" ngbDropdown  #myDropdown="ngbDropdown">
        <button type="button" class="btn header-item" ngbDropdownToggle data-toggle="dropdown" aria-haspopup="false"
          aria-expanded="false">
          {{projectSelector}}
          <i class="mdi mdi-chevron-down"></i>
        </button>
        <div class="dropdown-menu dropdown-megamenu" ngbDropdownMenu>
          <div class="row ">
            <div class="col-sm-12">
              <div class="row">
                <div class="col-sm-12  pb-3">
                  <h3 class="float-sm-left">Please Select a Project</h3>
                  <a class="float-sm-right text-dark" (click)='myDropdown.close()' routerLink='/projects/create'>
                    <div class="d-flex flex-row justify-content-start align-items-start">
                      <span class="bx bx-folder-plus bx-sm mr-1"></span>
                      <h5 class="mt-1"> NEW PROJECT</h5>
                    </div>   
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="table-responsive">
                  <table class="table mb-0">
                    <thead>
                      <tr >
                        <th>#</th>
                        <th>Project Name</th>
                        <th>Project ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor='let project of projectsData;let i = index' (click)='onProjectclick(project);myDropdown.close()'>              
                        <th scope="row">{{i+1}}</th>
                        <td>{{project.name}}</td>
                        <td>{{project.id}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center">

      <div class="dropdown d-inline-block d-lg-none ml-2" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" data-toggle="dropdown"
          aria-haspopup="true" ngbDropdownToggle aria-expanded="false">
          <i class="mdi mdi-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0" ngbDropdownMenu
          aria-labelledby="page-header-search-dropdown">

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>





      <div class="dropdown d-none d-lg-inline-block ml-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
          ngbDropdownToggle>
          <i class="bx bx-bell"></i>
          <span class="badge badge-primary badge-pill">0</span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
          aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">{{ 'HEADER.NOTIFICATIONS.TITLE' | translate}} </h6>
              </div>
              <div class="col-auto">
                <a href="javascript: void(0);" class="small">{{ 'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</a>
              </div>
            </div>
          </div>
          <perfect-scrollbar style="position: relative; height: 230px;" [config]="configData">
          </perfect-scrollbar>
          <div class="p-2 border-top">
            <a class="btn btn-sm btn-light btn-block text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-down-circle mr-1"></i> {{ 'HEADER.NOTIFICATIONS.LOAD_MORE' | translate}}
            </a>
          </div>
        </div>
      </div>
      <div>
         <i class="fas fa-lg dripicons-question"></i>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
          <img class="rounded-circle header-profile-user" [src]="userPhoto ? userPhoto:'assets/images/users/avatar-0.jpg'">
          <span class="d-none d-xl-inline-block ml-1">{{userName}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" routerLink='/users'><i class="bx bx-user font-size-16 align-middle mr-1"></i>
            {{"Account"}}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
        </div>
      </div>



    </div>
  </div>
</header>